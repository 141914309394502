<template>
    <div class="teaching_data">
        <div class="ls_flex mb20">
            <a class="tab_item" v-for="(item, index) in menu" :key="index" @click="changeMenu(index)">
                {{ item.name }}
            </a>
        </div>
        <div class="opt_path mb20">
            当前位置：
            <span>{{ menu[currentIndex].name }}</span>
        </div>

        <div v-if="currentIndex == 0" class="chart_list">
            <!-- 作品数据 -->
            <div id="zpsj"></div>
            <!-- 用户数据 -->
            <div id="yhsj"></div>
            <!-- 比赛数据 -->
            <div id="bssj"></div>
            <!-- 课件数据 -->
            <div id="kjsj"></div>
        </div>
        <tab-1 v-else-if="currentIndex == 1" />
        <tab-2 v-else-if="currentIndex == 2" />
        <tab-3 v-else-if="currentIndex == 3" />
        <tab-4 v-else-if="currentIndex == 4" />
    </div>
</template>

<script>

// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from 'echarts/core';
// 引入柱状图图表，图表后缀都为 Chart
import { BarChart, LineChart } from 'echarts/charts';
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
    TitleComponent,
    TooltipComponent,
    GridComponent,
    DatasetComponent,
    TransformComponent,
    LegendComponent
} from 'echarts/components';
// 标签自动布局、全局过渡动画等特性
import { LabelLayout, UniversalTransition } from 'echarts/features';
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from 'echarts/renderers';
import tab1 from './components/tab1.vue';
import tab2 from './components/tab2.vue';
import tab3 from './components/tab3.vue';
import tab4 from './components/tab4.vue';

// 注册必须的组件
echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    DatasetComponent,
    TransformComponent,
    LegendComponent,
    BarChart,
    LineChart,
    LabelLayout,
    UniversalTransition,
    CanvasRenderer
]);
export default {
    components: { tab1, tab2, tab3, tab4 },
    data () {
        return {
            menu: [
                { name: "数据大屏" },
                { name: "作品创作统计" },
                { name: "比赛统计" },
                { name: "课件统计" },
                { name: "参与人数统计" },
            ],
            currentIndex: 0
        };
    },
    mounted () {
        this["init" + this.currentIndex]();
    },
    methods: {
        changeMenu (index) {
            this.currentIndex = index;
            this['init' + index]();
        },

        // 数据大屏
        init0 () {
            const getChart = (url, id, title, type = "bar") => {
                this.$ajax.post(url, {}).then(res => {

                    var domChart = echarts.init(document.getElementById(id));
                    let series = [];
                    for (let i = 0; i < res.data.dataset[0].source.length - 1; i++) {
                        series.push({ type, seriesLayoutBy: 'row' })
                    }

                    let option = {
                        title: {
                            text: title
                        },
                        legend: {
                            // selectedMode: 'single', 选中某一项
                            top: 10,
                            right: 50
                        },
                        tooltip: { trigger: 'item' },
                        xAxis: { type: 'category' },
                        yAxis: {},
                        dataset: res.data.dataset,
                        series: series,
                    };
                    domChart.setOption(option);
                })
            }

            getChart("/report/product", "zpsj", "作品数据", "bar")
            getChart("/report/user", "yhsj", "用户数据", "line")
            getChart("/report/competition", "bssj", "比赛数据", "bar")
            getChart("/report/courseware", "kjsj", "课件数据", "bar")
        },
        // 作品创作统计
        init1 () { },
        // 比赛统计
        init2 () { },
        // 课件统计
        init3 () { },
        // 参与人数统计
        init4 () { },
    },
};
</script>

<style lang="scss" scoped>
.teaching_data {
    padding: 20px 20px 0 0;

    .tab_item {
        flex: 1;
        border-radius: 5px;
        border: 1px solid #8bb229;
        text-align: center;
        font-size: 16px;
        line-height: 40px;
        color: #fff;
        background: #8bb229;
        box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
        cursor: pointer;

        &:hover {
            background: #9bc434;
            color: #fff;
        }

        +.tab_item {
            margin-left: 35px;
        }
    }


    .chart_list {
        margin-top: 33px;
        width: 1114px;
        margin: 33px auto 0 auto;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;

        >div {
            width: 500px;
            height: 300px;
        }
    }
}
</style>
